export const InvierteFecha = (fecha: any) => {
    let fechaInicial = fecha.slice(0, 10);
    let fechaArray = fechaInicial.split("-");
    let fechaFinal = fechaArray[2] + "-" + fechaArray[1] + "-" + fechaArray[0];

    return fechaFinal;
}


export const OrdenaFechaServer = (fechaExterna:any)=>{


    let arrayfecha = fechaExterna.split("/")
    let fechaOrdenada = arrayfecha[2] + "/" + arrayfecha[1] + "/" + arrayfecha[0];

    return fechaOrdenada;

}


export const formatoFechaQuery = (fechaExterna:any)=>{
    let fechaQuery = fechaExterna.replace("/", "-").replace("/", "-") + "T";
    return fechaQuery;
}

export const FechaCalculo = (fecha: any) => {
    let fechaInicial = fecha.slice(0, 10);
    return fechaInicial;
}

export const HoraCorrecta = (hora: any) => {
    let horaFinal = hora.slice(11,16);
    return horaFinal;
}

export const CaracteresEspeciales = (str: any) => {
    return String(str).replace('&ntilde;', 'ñ')
        .replace('&Ntilde;', 'Ñ')
        .replace('&amp;'   , '&')
        .replace('&Ntilde;', 'Ñ')
        .replace('&ntilde;', 'ñ')
        .replace('&Ntilde;', 'Ñ')
        .replace('&Aacute;', 'Á')
        .replace('&Aacute;', 'Á')
        .replace('&Aacute;', 'Á')
        .replace('&Aacute;', 'Á')
        .replace('&Eacute;', 'É')
        .replace('&Eacute;', 'É')
        .replace('&Eacute;', 'É')
        .replace('&Eacute;', 'É')
        .replace('&Iacute;', 'Í')
        .replace('&Iacute;', 'Í')
        .replace('&Iacute;', 'Í')
        .replace('&Iacute;', 'Í')
        .replace('&Ntilde;', 'Ñ')
        .replace('&Ntilde;', 'Ñ')
        .replace('&Ntilde;', 'Ñ')
        .replace('&Ntilde;', 'Ñ')
        .replace('&Oacute;', 'Ó')
        .replace('&Oacute;', 'Ó')
        .replace('&Oacute;', 'Ó')
        .replace('&Oacute;', 'Ó')
        .replace('&Uacute;', 'Ú')
        .replace('&Uacute;', 'Ú')
        .replace('&Uacute;', 'Ú')
        .replace('&Uacute;', 'Ú')
        .replace('&aacute;', 'á')
        .replace('&aacute;', 'á')
        .replace('&aacute;', 'á')
        .replace('&aacute;', 'á')
        .replace('&eacute;', 'é')
        .replace('&eacute;', 'é')
        .replace('&eacute;', 'é')
        .replace('&eacute;', 'é')
        .replace('&iacute;', 'í')
        .replace('&iacute;', 'í')
        .replace('&iacute;', 'í')
        .replace('&iacute;', 'í')
        .replace('&ntilde;', 'ñ')
        .replace('&ntilde;', 'ñ')
        .replace('&ntilde;', 'ñ')
        .replace('&ntilde;', 'ñ')
        .replace('&oacute;', 'ó')
        .replace('&oacute;', 'ó')
        .replace('&oacute;', 'ó')
        .replace('&oacute;', 'ó')
        .replace('&uacute;', 'ú')
        .replace('&uacute;', 'ú')
        .replace('&uacute;', 'ú')
        .replace('&uacute;', 'ú')
        .replace(`\n`, '')
        .replace(`\n`, '')
        .replace(`\n`, '')
        .replace(`\n`, '')
        .replace(`\n`, '')
        .replace(`\r`, '')
        .replace(`\r`, '')
        .replace(`\r`, '')
        .replace(`\r`, '')
        .replace(`\r`, '')

}
