import { Navbar, Container } from "react-bootstrap";

import logoBetrack from "../../ASSETS/images/beetrack.png";
import logoSTK from "../../ASSETS/images/logo-header.png";
import { ReactChild, ReactFragment, ReactPortal } from "react";


export default function Menu(props: { logo: any; titulo: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) {

  let logo = props.logo;

  return (
    <>
      <Navbar className="navbar " bg="dark" expand="lg">
        <Container>

          <Navbar.Brand>
            <div className="row">



              {
                (logo === "beetrack") ? (
                  <div className="col">
                    <img alt="Beetrack" src={logoBetrack} style={{ height: "35px" }} />
                  </div>
                ) : (
                  <div className="col">
                    <img alt="Beetrack" src={logoSTK} style={{ height: "35px" }} />

                  </div>
                )
              }


              <div className="col">
                <h5 style={{ color: "white" }}>  {props.titulo} </h5>
              </div>


            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}
