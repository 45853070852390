

export const DelaySeg = (valor:any) => {

    return new Promise(function (resolve) {
        setTimeout(resolve, valor * 1000);
      });

}

export const DelayMin = (valor:any) => {

    return new Promise(function (resolve) {
        setTimeout(resolve, valor * 60000);
      });

} 






