import { RequestResponse } from "../../DTO/requestResponse";
import { Request } from "../../CONSTANTS/request";
import { MICROSERVICIOS } from "../../CONSTANTS/constants";
import { RezagoOrdenFlete } from "./rezagoOrdenFlete.dto";
import { RezagoBulto } from "./rezagoBulto.dto";


export default class Rezago {


    // Rezago.DatosRezagoOrdenFlete
    static async DatosRezagoOrdenFlete(rezago: RezagoOrdenFlete): Promise<RequestResponse> {

        let direccion = "OdflCodigoRezago/Datos";

        return new Request(MICROSERVICIOS.REZAGO).postTopDesk(direccion , rezago);


    }

        // Rezago.DatosRezagoBulto
        static async DatosRezagoBulto(rezago: RezagoBulto): Promise<RequestResponse> {

            let direccion = "BultoCodigoRezago/Datos";

            return new Request(MICROSERVICIOS.REZAGO).postTopDesk(direccion , rezago);
    
    
        }
}