import React, { useEffect } from "react";
import { inicio } from "./API/CONSTANTS/constants";


export default function App(props: any) {

  const { history } = props;

  function validacion() {
    if (inicio.permitido === false) {
      PaginaError();
    }
  }

  function PaginaError() {
    history.push("/InValidDomain");
  }

  useEffect(() => {
    validacion();
  // eslint-disable-next-line
  }, [])

  //=====================================================================================

  return (
    <>
      <h2> Página de Inicio Vacia  </h2>;
    </>
  )
}