import { RequestResponse } from "../../DTO/requestResponse";
import { Request } from "../../CONSTANTS/request";
import { consultaTrackingDetalleDto } from "./consultaTrackingDetalle.dto";
import { MICROSERVICIOS } from "../../CONSTANTS/constants";


export default class Tracking {


    // Tracking.detalle
    static async detalle(detalle: consultaTrackingDetalleDto): Promise<RequestResponse> {


        return new Request(MICROSERVICIOS.TRACKING_DETALLE).post("detalle", detalle);


    }
}