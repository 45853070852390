import Topdesk from "../API/MICROSERVICES/topdesk/topdesk";
import AlertaCentral from "./CentralAlertas";
import { Limpieza } from "./limpiador";
import { DelaySeg } from "./temporizadores";


export async function traeMiniTicket() {

    console.log("traeMiniTicket");

    let pesoMinimo = 0.1;
    let pesoMaximo = 50;

    let altoMinimo = 0.1;
    let altoMaximo = 50;

    let largoMinimo = 0.1;
    let largoMaximo = 50;

    let anchoMinimo = 0.1;
    let anchoMaximo = 50;


    let ArrayMaster: any[] | null = [];

    // llama al metodo de peso;
    const resultadosPESO: any[] = await Topdesk.BusquedaRangoPeso(pesoMinimo, pesoMaximo).then(async (resp) => {

        if (resp.error) {
           
            AlertaCentral("ERROR");
           
        } else {

            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            }

        }

    });

    await DelaySeg(1);

    // llama al metodo de alto
    const resultadosALTO: any[] = await Topdesk.BusquedaRangoAlto(altoMinimo, altoMaximo).then(async (resp) => {


        if (resp.error) {
           
            AlertaCentral("ERROR");
         
        } else {

            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            }

        }

    });

    await DelaySeg(1);

    // llama al metodo de largo
    const resultadosLARGO: any[] = await Topdesk.BusquedaRangoLargo(largoMinimo, largoMaximo).then(async (resp) => {

        if (resp.error) {
          
            AlertaCentral("ERROR");
         
        } else {

            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            } 
        }

    });

    await DelaySeg(1);

    // llama al metodo de ancho
    const resultadosANCHO: any[] = await Topdesk.BusquedaRangoAncho(anchoMinimo, anchoMaximo).then(async (resp) => {

        if (resp.error) {
           
            AlertaCentral("ERROR");
        
        } else {

            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            }
        }
    });

    //encadena resultados 
    let ArrayConPESO = ArrayMaster.concat(resultadosPESO);
    let ArrayConALTO = ArrayConPESO.concat(resultadosALTO);
    let ArrayConLARGO = ArrayConALTO.concat(resultadosLARGO);
    let ArrayConANCHO = ArrayConLARGO.concat(resultadosANCHO);

    // limpia los duplicados

    let arraylimpio: any[] = [];

    if(arraylimpio.length != 0){
        
        arraylimpio = Limpieza(ArrayConANCHO);
    }

    if(arraylimpio.length != 0 ){
        return arraylimpio;
    }else{
        return null;
    }

}

export async function traeBigTicket() {

    console.log("traeBigTicket");

    let pesoMinimo = 51;
    let pesoMaximo = 100;

    let altoMinimo = 51;
    let altoMaximo = 100;

    let largoMinimo = 51;
    let largoMaximo = 100;

    let anchoMinimo = 51;
    let anchoMaximo = 100;

    let ArrayMaster: any[] | null = [];
  
    // llama al metodo de peso;
    const resultadosPESO: any[] = await Topdesk.BusquedaRangoPeso(pesoMinimo, pesoMaximo).then(async (resp) => {

        if (resp.error) {
         
            AlertaCentral("ERROR");
           
        } else {

            let peso = await resp.data;

            if (peso.length > 0) {

                return resp.data;
            } 
        }

    });

    await DelaySeg(1);

    // llama al metodo de alto
    const resultadosALTO: any[] = await Topdesk.BusquedaRangoAlto(altoMinimo, altoMaximo).then(async (resp) => {

        if (resp.error) {
         
            AlertaCentral("ERROR");
          

        } else {

            let alto = await resp.data;

            if (alto.length > 0) {

                return resp.data;
            } 

        }

    });

    await DelaySeg(1);


    // llama al metodo de largo
    const resultadosLARGO: any[] = await Topdesk.BusquedaRangoLargo(largoMinimo, largoMaximo).then(async (resp) => {

        if (resp.error) {
           
            AlertaCentral("ERROR");
          
        } else {
           
            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            }
        }
    });

    await DelaySeg(1);

    // llama al metodo de ancho
    const resultadosANCHO: any[] = await Topdesk.BusquedaRangoAncho(anchoMinimo, anchoMaximo).then(async (resp) => {

        if (resp.error) {
         
            AlertaCentral("ERROR");
        
        } else {
           
            let ancho = await resp.data;

            if (ancho.length > 0) {

                return resp.data;
            } 
        }
    });

    //encadena resultados 
    let ArrayConPESO = ArrayMaster.concat(resultadosPESO);
    let ArrayConALTO = ArrayConPESO.concat(resultadosALTO);
    let ArrayConLARGO = ArrayConALTO.concat(resultadosLARGO);
    let ArrayConANCHO = ArrayConLARGO.concat(resultadosANCHO);



   
let arraylimpio: any[] = [];

if(arraylimpio.length != 0){
    
    arraylimpio = Limpieza(ArrayConANCHO);
}

    if(arraylimpio.length != 0){
        return arraylimpio;
    }else{
        return null;
    }
}

export async function traeGigaTicket() {

    console.log("traeGigaTicket");

    let pesoMinimo = 101;
    let altoMinimo = 101;
    let largoMinimo = 101;
    let anchoMinimo = 101;

    let ArrayMaster: any[] | null = [];

  
    // llama al metodo de peso;
    const resultadosPESO: any[] = await Topdesk.BusquedaRangoPesoMaximo(pesoMinimo).then(async (resp) => {

        if (resp.error) {
          
            AlertaCentral("ERROR");
            

        } else {
        



            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            } 


        }


    });
    await DelaySeg(1);

  

    // llama al metodo de alto
    const resultadosALTO: any[] = await Topdesk.BusquedaRangoAltoMaximo(altoMinimo).then(async (resp) => {


        if (resp.error) {
           
            AlertaCentral("ERROR");
            

        } else {
           



            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            } 


        }

    });
    await DelaySeg(1);


  
    // llama al metodo de largo
    const resultadosLARGO: any[] = await Topdesk.BusquedaRangoLargoMaximo(largoMinimo).then(async (resp) => {

        if (resp.error) {
          
            AlertaCentral("ERROR");
           

        } else {
          



            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            } 


        }

    });

    await DelaySeg(1);

  
    // llama al metodo de ancho
    const resultadosANCHO: any[] = await Topdesk.BusquedaRangoAnchoMaximo(anchoMinimo).then(async (resp) => {

        if (resp.error) {
          
            AlertaCentral("ERROR");
         

        } else {
         



            let largo = await resp.data;

            if (largo.length > 0) {

                return resp.data;
            } 



        }

    });


    //encadena resultados 
    let ArrayConPESO = ArrayMaster.concat(resultadosPESO);
    let ArrayConALTO = ArrayConPESO.concat(resultadosALTO);
    let ArrayConLARGO = ArrayConALTO.concat(resultadosLARGO);
    let ArrayConANCHO = ArrayConLARGO.concat(resultadosANCHO);

    
    let arraylimpio: any[] = [];

    if(arraylimpio.length != 0){
        
        arraylimpio = Limpieza(ArrayConANCHO);
    }


    if(arraylimpio.length != 0){

        return arraylimpio;

    }else{
        return null;
    }

}