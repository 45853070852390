
import React, { useEffect, useState } from "react";

import axios from "axios";

import Rezago from '../../API/MICROSERVICES/rezago/rezago';
import Tracking from "../../API/MICROSERVICES/tracking/tracking";



export default function Pruebas(props: any) {


  useEffect(() => {
    algo();

  }, []);


  async function algo() {

    console.log("Ejecutando algo");




    
    let of2 = { of: 705059546 };

   let encargos = await  Tracking.detalle(of2).then(async (resp)=> {

    

      return resp.data.encargos;

    });

    console.log("encargos", encargos);







  }



  return (
    <>
      <h1>Hola Mundo</h1>
      <h1>Chao Mundo</h1>

    </>
  )

}