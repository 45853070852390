import { Limpieza } from "../../../SHARED/limpiador";


export async function test(mensaje:string){
    let texto:string = "Imprimo un mensaje " + mensaje;
    console.log(texto)
    return texto;
}


export async function ActualizaColor(image:any){

 
    
    
    let clase = image.categoria;
    let color = "";

    if(clase == 0){
        
        color = "rgb(231, 255, 233)";

         }else if(clase == 1){
             color = "Lavender";
         }else if(clase == 2){
             color = "PaleGreen";
         }else if(clase == 3){
             color = "LightSkyBlue";
         
         }else{
             color = "Bisque"; 
         }


         return color;
        

}



export async function CalcularTipoTicket(itemExt: any){

    let TipoTicket:string = "No hay Clasificación Asociada";
    let MiniTicket:string = "MINI TICKET";
    let BigTicket:string  = "BIG TICKET";
    let GigaTicket:string = "GIGA TICKET";

    let alto = itemExt.alto;
    let largo = itemExt.largo;
    let ancho = itemExt.ancho;
    let peso = itemExt.peso;



    let ArrayTickets:any[] = [];

    let TicketAlto  = "";
    let TicketLargo = "";
    let TicketAncho = "";
    let TicketPeso  = "";

    if(alto >= 0 && alto <= 50 ){
        TicketAlto = MiniTicket;
    }else if(alto >= 51 && alto <= 100){
        TicketAlto = BigTicket;
    }else{
        TicketAlto = GigaTicket;
    }
    ArrayTickets.push(TicketAlto);


    if(largo >= 0 && largo <= 50 ){
        TicketLargo = MiniTicket;
    }else if(largo >= 51 && largo <= 100){
        TicketLargo = BigTicket;
    }else{
        TicketLargo = GigaTicket;
    }        
    ArrayTickets.push(TicketLargo);


    if(ancho >= 0 && ancho <= 50 ){
        TicketAncho = MiniTicket;
    }else if(ancho >= 51 && ancho <= 100){
        TicketAncho = BigTicket;
    }else{
        TicketAncho = GigaTicket;
    }       
    ArrayTickets.push(TicketAncho);


    if(peso >= 0 && peso <= 50 ){
        TicketPeso = MiniTicket;
    }else if(peso >= 51 && peso <= 100){
        TicketPeso = BigTicket;
    }else{
        TicketPeso = GigaTicket;
    }

    ArrayTickets.push(TicketPeso);




    let Limpio = await Limpieza(ArrayTickets);



    let largoArray = Limpio.length;


    if(largoArray == 1){
        TipoTicket = ""+Limpio[0];
    }else if(largoArray == 2){
        TipoTicket = ""+Limpio[1];
    }else if(largoArray == 3){
        TipoTicket = ""+Limpio[3];
    }else{

    }


    return TipoTicket;

}


export async function cargaImagenes(itemImg: any) {

    console.log("itemImg",itemImg);
       

    let imagenes = itemImg;
    let URLS: any[] = []

    console.log("imagenes dentro -> ", imagenes);

    if (imagenes != undefined) {

        if (imagenes != "") {

            let array = imagenes.split(`\n`);


            array.forEach((item: any, index: any) => {

                if (index > 0) {

                    console.log(item.slice(9));

                    URLS.push(item.slice(9));
                }
            });
        }
    }
    return URLS;
}


export async function cancelar() {
    window.location.reload();
}




